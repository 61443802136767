import { identity } from "deso-protocol";
import { useContext, useState, useEffect, useRef } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import { Link } from "react-router-dom";
import { getDisplayName } from "../helpers";
import { Avatar, AvatarImage } from "components/ui/avatar";
import { Button } from "components/ui/button";

export const Nav = () => {
  const { currentUser, alternateUsers, isLoading } = useContext(DeSoIdentityContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown element

  const getProfilePictureUrl = (user) => {
    return `https://diamondapp.com/api/v0/get-single-profile-picture/${user.PublicKeyBase58Check}?fallback=https://bitclout.com/assets/img/default_profile_pic.png`;
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav className="bg-gray-900 sticky z-50 top-0 text-white px-6 py-4 flex justify-end items-center">

      {isLoading ? (
        <div>Loading...</div>
      ) : currentUser ? (
        <div className="relative">
          {/* User avatar and name, toggle the dropdown */}
          <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
            <Avatar>
              <AvatarImage
                src={getProfilePictureUrl(currentUser)}
                alt={getDisplayName(currentUser)}
              />
            </Avatar>
            <span className="ml-3">{getDisplayName(currentUser)}</span>
          </div>

          {/* Dropdown menu */}
          {isDropdownOpen && (
            <div
              className="absolute right-0 mt-2 w-48 bg-white text-black rounded-lg shadow-lg z-50"
              ref={dropdownRef} // Reference for detecting outside clicks
            >
              <ul className="py-2">
                {/* Switch accounts if there are alternate users */}
                {alternateUsers.length > 0 && (
                  <>
                    <li className="px-4 py-2 text-sm font-semibold">Switch Accounts</li>
                    {alternateUsers.map((user) => (
                      <li
                        key={user.PublicKeyBase58Check}
                        className="px-4 py-2 flex items-center hover:bg-gray-100 cursor-pointer"
                        onClick={() => identity.setActiveUser(user.PublicKeyBase58Check)}
                      >
                        <AvatarImage
                          src={getProfilePictureUrl(user)}
                          className="w-8 h-8 rounded-full mr-3"
                          alt={getDisplayName(user)}
                        />
                        <span>{getDisplayName(user)}</span>
                      </li>
                    ))}
                    <div className="border-t my-2"></div>
                  </>
                )}
                {/* Add another account option */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => identity.login()}
                >
                  Add another account
                </li>
                {/* Logout option */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-600"
                  onClick={() => identity.logout()}
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      ) : (
        <Button onClick={() => identity.login()}>Login</Button>
      )}
    </nav>
  );
};
