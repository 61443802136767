import React, { useState, useEffect } from "react";
import BigNumber from 'bignumber.js';

const NFTPurchaseCard = ({ currentUser, availableNFTs, handlePurchaseNFT }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [userTokens, setUserTokens] = useState(0);

  const sharkGangCreatorPublicKey =
    "BC1YLfgpcEWRhUWpxNYwzPYMADgL3F1YWX5aN9Wew16g99Fk1BBiYpX";

  // Fetch user's DAO token balance using is-hodling-public-key API
  const fetchUserDAOTokenBalance = async (currentUser, creatorPublicKey) => {
    if (!currentUser?.PublicKeyBase58Check || !creatorPublicKey) {
      setErrorMessage("Invalid public keys.");
      return 0;
    }

    try {
      const response = await fetch(
        "https://node.deso.org/api/v0/is-hodling-public-key",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            PublicKeyBase58Check: currentUser.PublicKeyBase58Check,
            IsHodlingPublicKeyBase58Check: creatorPublicKey,
            FetchAll: true,
            FetchHodlings: true,
            IsDAOCoin: true,
          }),
        }
      );

      const data = await response.json();

      if (data.error) {
        setErrorMessage(data.error);
        return 0;
      }

      if (data.IsHodling && data.BalanceEntry) {
        const balanceNanos = new BigNumber(data.BalanceEntry.BalanceNanosUint256); // BalanceNanos from your JSON
        const balanceInTokens = balanceNanos.dividedBy(new BigNumber("1e18")).toNumber();
        return balanceInTokens;
      } else {
        return 0;
      }
    } catch (error) {
      setErrorMessage("Failed to fetch token balance.");
      return 0;
    }
  };

  useEffect(() => {
    const fetchTokenBalance = async () => {
      if (currentUser && currentUser.PublicKeyBase58Check) {
        const balance = await fetchUserDAOTokenBalance(
          currentUser,
          sharkGangCreatorPublicKey
        );
        setUserTokens(balance);
      }
    };

    fetchTokenBalance();
  }, [currentUser]);

  const handleConfirmPurchase = async () => {
    setErrorMessage("");
  
    if (!currentUser) {
      setErrorMessage("Please log in to make a purchase.");
      return;
    }
  
    const transactionSpendingLimit = {
      GlobalDESOLimit: 1000000000, // Set the DESO spending limit
      DAOCoinOperationLimitMap: {
        [sharkGangCreatorPublicKey]: {
          transfer: 1, // Allow 1 DAO coin transfer for SharkGang DAO
        },
      },
    };
  
    // Stringify and encode the TransactionSpendingLimit object
    const transactionSpendingLimitEncoded = encodeURIComponent(
      JSON.stringify(transactionSpendingLimit)
    );
  
    // Construct the URL with the necessary parameters and encode it
    const deriveUrl = `https://identity.deso.org/derive?TransactionSpendingLimit=${transactionSpendingLimitEncoded}&PublicKey=${encodeURIComponent(
      currentUser.PublicKeyBase58Check
    )}`;
  
    // Open the DeSo Identity window for key derivation
    const deriveWindow = window.open(deriveUrl, "_blank");
  
    const randomNFT =
      availableNFTs[Math.floor(Math.random() * availableNFTs.length)];
  
    // Step 2: Listen for the message event when the derived key is generated
    window.addEventListener("message", async (event) => {
      if (event.data.method === "derive" && event.data.service === "identity") {
        const {
          derivedPublicKeyBase58Check,
          accessSignature,
          expirationBlock,
        } = event.data.payload;
  
        console.log("Derived Key Generated:", derivedPublicKeyBase58Check);
  
        // Step 3: Authorize the derived key using the /authorize-derived-key endpoint
        try {
          const response = await fetch("/api/authorize-derived-key", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              OwnerPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
              DerivedPublicKeyBase58Check: derivedPublicKeyBase58Check,
              ExpirationBlock: expirationBlock,
              AccessSignature: accessSignature,
              TransactionSpendingLimitHex: transactionSpendingLimitEncoded,
              Memo: "NFT Swap Authorization",
            }),
          });
  
          const result = await response.json();
  
          if (result.error) {
            setErrorMessage(result.error);
            return;
          }
  
          console.log("Derived Key Authorized:", result);
  
          // Proceed with the NFT purchase after derived key is authorized
          handlePurchaseNFT(randomNFT);
        } catch (error) {
          console.error("Failed to authorize derived key:", error);
          setErrorMessage("Failed to authorize derived key.");
        }
      }
    });
  };
  

  return (
    <div className="flex flex-col justify-between h-full p-4 text-center bg-white bg-opacity-95 mx-2 rounded-2xl border-2 border-slate-200 shadow w-full max-w-xl min-h-[400px]">
      <div className="flex flex-col gap-4 items-center justify-center flex-1">
        <div className="font-bold text-center text-xl mt-4">
          Swap SharkGang Tokens for a Random SharkGang NFT
        </div>

        {/* User Balance */}
        <div className="w-full bg-white border-2 rounded-xl p-2">
          <div className="text-black font-bold">
            Your Token Balance: {userTokens.toFixed(0)} Tokens
          </div>
        </div>

        {/* Error Message Display */}
        {errorMessage && (
          <div className="text-red-500 font-bold mt-2">{errorMessage}</div>
        )}

        {/* Available NFTs */}
        {currentUser ? (
          availableNFTs && availableNFTs.length > 0 ? (
            <div className="flex overflow-x-auto gap-2 pb-0 nft-display max-w-full">
              {availableNFTs.map((nft, index) => (
                <div
                  key={index}
                  className="w-24 h-auto pb-2 bg-gray-800 rounded-xl shadow-lg flex-shrink-0"
                >
                  <div className="relative w-24 h-24">
                    <img
                      className="w-full h-full object-cover nft-card-image"
                      src={nft.imageUrl}
                      alt={`NFT ${index}`}
                    />
                  </div>
                  <div className="text-white font-bold truncate mt-1 text-xs">
                    {nft.name}
                  </div>
                  <div className="text-gray-400 text-xs truncate max-h-8 overflow-hidden">
                    {nft.price} Tokens
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-black">No NFTs available to swap.</div>
          )
        ) : (
          <div className="text-red-500 font-bold mb-4">Log in to buy NFTs.</div>
        )}
      </div>

      {/* Purchase Button */}
      <div className="w-full flex flex-col items-center justify-center rounded-xl py-4 mt-4">
        <button
          onClick={handleConfirmPurchase}
          className="custom-button mt-4 bg-blue-500 text-white rounded-md px-4 py-2"
        >
          {currentUser ? "Purchase NFT" : "Connect Wallet"}
        </button>
      </div>
    </div>
  );
};

export default NFTPurchaseCard;
