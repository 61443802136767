import React, { useState, useEffect } from 'react';

const NFTTransferCard = ({ currentUser, selectedNFTs, handleTransferNFTs, userNFTs, handleSelectNFT }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [loadingNFTs, setLoadingNFTs] = useState(true); // New state to track loading status
    const creatorPublicKey = "BC1YLiwZ1bd8xdUmXPoLMJtBVEN9hGUvayATeDfRq4Upwed6N2YMi68"; // The specific public key to filter by

    // Check if NFTs are still loading and update the state
    useEffect(() => {
        if (userNFTs && userNFTs.NFTsMap !== undefined) {
            setLoadingNFTs(false); // Stop loading once userNFTs is populated
        }
    }, [userNFTs]);

    // Filter NFTs by the specified creator public key
    const filteredNFTs = userNFTs && userNFTs.NFTsMap
        ? Object.keys(userNFTs.NFTsMap).filter(key => {
            const postEntryResponse = userNFTs.NFTsMap[key].PostEntryResponse;
            return postEntryResponse.ProfileEntryResponse?.PublicKeyBase58Check === creatorPublicKey &&
                !postEntryResponse.Body.startsWith("Shark Gang Lottery") &&
                !postEntryResponse.Body.includes("Shark Gang - Mint Pass");
        })
        : [];

    return (
        <div className="p-2 flex flex-col text-center overflow-x-hidden bg-white bg-opacity-95 mx-2 rounded-2xl border-2 border-slate-200 shadow w-full max-w-xl">
            <div className="flex flex-col gap-4 items-center justify-center p-4 rounded-2xl max-w-2xl mt-2">
                <div className="font-bold text-center text-xl">Swap SharkGang NFTs for SharkGang Tokens. Each NFT is worth 1000 Tokens!</div>
            </div>

            {/* Error Message Display */}
            {errorMessage && <div className="text-red-500 font-bold">{errorMessage}</div>}

            {/* Current User Logic */}
            {currentUser ? (
                loadingNFTs ? (
                    <div className="text-gray-500">Loading NFTs...</div> // Show loading while NFTs are being fetched
                ) : filteredNFTs.length > 0 ? ( // Show NFTs if the filtered list has NFTs
                    <div className="flex overflow-x-auto gap-2 pb-0 nft-display max-w-full">
                        {filteredNFTs.map((key, index) => {
                            const postEntryResponse = userNFTs.NFTsMap[key].PostEntryResponse;
                            const nftEntryResponses = userNFTs.NFTsMap[key].NFTEntryResponses;
                            const isSelected = selectedNFTs.some(nft => nft.NFTPostHashHex === postEntryResponse.PostHashHex && nft.SerialNumber === nftEntryResponses[0]?.SerialNumber);

                            return (
                                <div
                                    key={index}
                                    className={`w-24 h-auto pb-2 bg-gray-800 rounded-xl shadow-lg transform ${isSelected ? "scale-95" : ""} flex-shrink-0`}
                                    onClick={() => handleSelectNFT(postEntryResponse.PostHashHex, nftEntryResponses[0]?.SerialNumber, postEntryResponse.ImageURLs[0])}
                                >
                                    {postEntryResponse && postEntryResponse.ImageURLs && postEntryResponse.ImageURLs[0] && (
                                        <>
                                            <div className="relative w-24 h-24">
                                                <img className="w-full h-full object-cover nft-card-image" src={postEntryResponse.ImageURLs[0]} alt={`NFT ${index}`} />
                                                {isSelected && (
                                                    <div className="absolute top-2 right-2 bg-white rounded-full">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="text-white font-bold truncate mt-1 text-xs">{postEntryResponse.ProfileEntryResponse?.Username}</div>
                                            <div className="text-gray-400 text-xs truncate max-h-8 overflow-hidden">{postEntryResponse.Body}</div>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="text-black">No NFTs found.</div> // Display when no NFTs from the specific public key are found
                )
            ) : (
                <div className="text-red-500 font-bold mb-4">Log in to view and transfer your NFTs.</div>
            )}

            {/* Transfer Section */}
            <div className="w-full flex flex-col items-center justify-center rounded-xl py-4 mt-4">
                <div className="w-full bg-white border-2 rounded-xl p-2 mb-2">
                    <div className="flex flex-wrap gap-2 items-center">
                        <span className="text-black font-bold block py-2 text-sm">NFTs:</span>
                        {selectedNFTs.map((nft, index) => {
                            const imageUrl = nft.imageURL;

                            return (
                                <div key={index} className="relative h-8 w-8 bg-white rounded-full image-container border-2 border-white flex-shrink-0">
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            className="rounded-full"
                                            alt={`Selected NFT ${index}`}
                                        />
                                    ) : (
                                        <div className="h-full w-full bg-gray-300 rounded-full"></div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* Amount Calculation */}
                <div className="w-full flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-black" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v10a1 1 0 102 0V4a1 1 0 00-1-1z" clipRule="evenodd" />
                        <path fillRule="evenodd" d="M4.293 9.707a1 1 0 011.414 0L10 14.086l4.293-4.379a1 1 0 111.414 1.414l-5 5.086a1 1 0 01-1.414 0l-5-5.086a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </div>

                {/* Token Info */}
                <div className="w-full flex justify-between items-center bg-white border-2 rounded-xl p-2 py-3 mt-2">
                    <span className="text-black font-bold text-sm py-1">RECEIVE:</span>
                    <div className="flex items-center">
                        <div className="w-12 text-center">{selectedNFTs.length * 1000}</div>
                        <div>Tokens</div>
                    </div>
                </div>

                {/* Transfer Button */}
                <button onClick={handleTransferNFTs} className="custom-button mt-4" disabled={!currentUser}>
                    {currentUser ? "Transfer NFTs" : "Connect Wallet"}
                </button>
            </div>
        </div>
    );
};

export default NFTTransferCard;
