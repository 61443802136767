import React from "react";

const HeroSection = () => {
    return (
        <div className="relative w-full h-fit flex justify-center items-center">

            {/* Center content */}
            <div className="relative z-10 flex flex-col items-center justify-center text-center text-white px-4 py-16">
                <img src="/assets/bigLogo.PNG" alt="Logo" className="mb-4 w-64 z-0" />
                <p className="text-lg md:text-xl mb-6">DEV Dive into the depths of DeSo with our fin-tastic NFT collection!</p>
                <button className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-full">
                    Buy/Sell SharkGang Token
                </button>
            </div>
        </div>
    );
};

export default HeroSection;
