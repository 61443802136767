import React, { useContext, useState, useEffect } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import { getNFTsForUser, transferNFT } from "deso-protocol";
import { getDisplayName } from "../helpers";
import NFTTransferCard from "components/NFTTransferCard";
import NFTPurchaseCard from "components/NFTPurchaseCard";
import axios from "axios";
import HeroSection from "components/HeroSection";

export const HomeTwo = () => {
    const { currentUser, isLoading } = useContext(DeSoIdentityContext);
    const [userNFTs, setUserNFTs] = useState({});
    const [selectedNFTs, setSelectedNFTs] = useState([]);

    useEffect(() => {
        const fetchUserNFTs = async () => {

            if (currentUser) { 
                try {
                    const response = await fetch("https://node.deso.org/api/v0/get-user-derived-keys", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        PublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                      }),
                    });

                    const result = await response.json();

                    console.log(result);
                } catch (error) {
                    console.error(error);
                }
            }

            if (currentUser) {
                try {
                    const testNFTs = await getNFTsForUser({
                        UserPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                    });
                    if (!testNFTs || Object.keys(testNFTs.NFTsMap).length === 0) {
                        setUserNFTs(null); // Handle no NFTs found
                    } else {
                        setUserNFTs(testNFTs);
                    }
                    console.log("User NFTS:", testNFTs);
                } catch (error) {
                    console.error("Error fetching user NFTs:", error);
                    setUserNFTs(null); // Handle error state
                }
            }
        };

        fetchUserNFTs();
    }, [currentUser]);

    const handleTransferNFTs = async () => {
        if (!currentUser) {
            console.error("User not logged in");
            return;
        }

        const transferDataArray = [];

        for (const nft of selectedNFTs) {
            try {
                const response = await transferNFT({
                    SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                    ReceiverPublicKeyBase58Check: "BC1YLgqGxRGuFob1a8MmZZWt8B7LDYTuPCKK9wB3AC5G3WL21WwgenE",
                    NFTPostHashHex: nft.NFTPostHashHex,
                    SerialNumber: nft.SerialNumber,
                    MinFeeRateNanosPerKB: 1700,
                });

                console.log("Transfer NFT response:", response);

                const transferData = {
                    publicKey: currentUser.PublicKeyBase58Check,
                    username: getDisplayName(currentUser),
                    transferHash: response.TransactionHex,
                    nftHash: nft.NFTPostHashHex,
                    nftSerial: nft.SerialNumber
                };

                transferDataArray.push(transferData);

                console.log('NFT transfer information added to array');
            } catch (error) {
                console.error("Error transferring NFT:", error);
            }
        }

        try {
            await axios.post('https://2f73idkhhk.execute-api.us-west-2.amazonaws.com/production/tokens4nft', transferDataArray);
            console.log('All NFT transfer information sent successfully');
        } catch (error) {
            console.error("Error sending NFT transfer information:", error);
        }

        setSelectedNFTs([]);
    };

    const handleSelectNFT = (nftPostHashHex, serialNumber, imageURL) => {
        setSelectedNFTs((prevSelectedNFTs) => {
            const isSelected = prevSelectedNFTs.some(nft => nft.NFTPostHashHex === nftPostHashHex && nft.SerialNumber === serialNumber);
            if (isSelected) {
                return prevSelectedNFTs.filter(nft => nft.NFTPostHashHex !== nftPostHashHex || nft.SerialNumber !== serialNumber);
            } else {
                return [...prevSelectedNFTs, { NFTPostHashHex: nftPostHashHex, SerialNumber: serialNumber, imageURL }];
            }
        });
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const filterKey = "BC1YLfgpcEWRhUWpxNYwzPYMADgL3F1YWX5aN9Wew16g99Fk1BBiYpX";

    return (
        <div className="bg-page-background">
            <HeroSection />
            <div className="flex flex-col md:flex-row items-start justify-center gap-4 py-4 px-4">
                {/* NFT Transfer Card */}
                <div className="flex flex-col gap-4 items-center justify-center py-4 px-4 md:px-2 w-full max-w-md h-full">
                    <NFTTransferCard 
                        currentUser={currentUser} 
                        selectedNFTs={selectedNFTs} 
                        handleTransferNFTs={handleTransferNFTs} 
                        userNFTs={userNFTs} 
                        handleSelectNFT={handleSelectNFT}
                        filterKey={filterKey}
                    />
                </div>

                {/* NFT Purchase Card */}
                <div className="flex flex-col gap-4 items-center justify-center py-4 px-4 md:px-2 w-full max-w-md h-full">
                    <NFTPurchaseCard 
                        currentUser={currentUser} 
                        availableNFTs={[]} // Replace with actual available NFTs
                        handlePurchaseNFT={(nft) => console.log('Purchasing NFT:', nft)}
                        userTokens={10000} // Replace with actual user tokens
                    />
                </div>
            </div>
        </div>
    );
};

export default HomeTwo;
